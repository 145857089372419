import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getSubCategory } from '../actions/MainAction';
import { withHooks } from '../actions/withHooks';
export class SubCategoryPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        category_id: this.props.params.id,
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title"],
        requireds:[],
        afterCallFunction:this.props.getSubCategory,
        afterParams:[this.props.params.id],
        url:'admin-sub-categories'
      }
    })
  }

  componentDidMount(){
    this.props.getSubCategory(this.props.params.id)
  }
  render() {
    const {subCategoryData} = this.props;
    // console.log(subCategoryData)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Sub category" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            subCategoryData?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-sub-categories/${data?.id}`}
                  afterCallFunction={()=> this.props.getSubCategory(this.props.params.id)}
                  url={`/products/${data?.id}`}
                  urlText="Products"
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    subCategoryData: state.Data.subCategoryData
});
const mapDispatchToProps = {changeStateValue, getSubCategory}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SubCategoryPage))