import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getProductImage } from '../actions/MainAction';
import { withHooks } from '../actions/withHooks';
export class ProductImage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        product_id: data?.product_id?data?.product_id:this.props.params.id,
        image: data?.image?data?.image:"",
        image_full_url: data?.image_full_url?data?.image_full_url:"",
        requiredsLang: [],
        requireds:[],
        afterCallFunction:this.props.getProductImage,
        afterParams:[this.props.params.id],
        url:'admin-product-image'
      }
    })
  }

  componentDidMount(){
    this.props.getProductImage(this.props.params.id)
  }
  render() {
    const {productImages} = this.props;
    // console.log(productImages)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Product images" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            productImages?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  deleteUrl={`admin-product-image/${data?.id}`}
                  afterCallFunction={()=> this.props.getProductImage(this.props.params.id)}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    productImages: state.Data.productImages
});
const mapDispatchToProps = {changeStateValue, getProductImage}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ProductImage))
