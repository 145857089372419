import Swal from "sweetalert2";

export const bigAlert=(title="Error!", text="Something went wrong!", icon="error")=>{
    Swal.fire({
        title: title,
        text: text,
        icon: icon
      });
}
export const mixinAlert=(icon="error", title="Something went wrong!")=>{
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: icon,
        title: title
      });
}

export const confirmAlert=(icon, title, text, btnText="Yes, delete it!", html="")=>{
  return new Promise((res, rej)=>{
    Swal.fire({
      title:title,
      text: text,
      icon: icon,
      html:html,
      showCancelButton: true,
      confirmButtonColor: "blue",
      cancelButtonColor: "red",
      confirmButtonText: btnText
    }).then((result) => {
      if (result.isConfirmed) {
       res("success")
      }
    });
  })
}