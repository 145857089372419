import React, { Component } from 'react'
import { connect } from 'react-redux';
import { insertNewData } from '../../../actions/MainAction';
import { mixinAlert } from '../../../actions/SweetAlerts';
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer';
export class Footer extends Component {
  state={
    btnDisable:false
  }
  closeBtn=()=>{
    this.props.changeStateValue({
      name:"mainModal"
    })
  }
  addBtn=(data)=>{
    let permission = true;
    for(const x of data.requireds){
      if(data[x]=="" || !data[x]){
        permission = false;
      }
    }
    for(const x of data.requiredsLang){
      if(data?.languages[x]=="" || !data?.languages[x]){
        permission = false;
      }
    }
    if(permission){
      this.setState({btnDisable:true})
      let formData = new FormData();
      for(const property in data){
        if(data[property] && property !=="languages"){
          formData.append(property, data[property]);
        }
      }
      if(!data?.video){
        formData.append("video", null)
      }
      if(!data?.image){
        formData.append("image", null)
      }
      if(data?.languages){
        formData.append("translates", JSON.stringify([data?.languages]))
      }
      let x = 0;
      if(data?.images){
        for(let i = 0; i < data?.images.filter(x=>x.id=='').length; i++){
          formData.append(`image${i}`, data?.images.filter(x=>x.id=='')[i]?.image);
          x++;
        }
      }
      console.log(data?.deleted_images)
      if(data?.deleted_images){
        formData.append("deleted_images", JSON.stringify(data?.deleted_images))
      }
      formData.append("image_count", x);
     
      let url = data?.url;
      if(data?.id && data?.id !=""){
        url = `${data?.url}/${data?.id}`;
        formData.append("_method", "put")
      }
      this.props.insertNewData(url, formData)
      .then(resp=>{
        this.setState({btnDisable:false})
        if(resp==="success"){
          this.closeBtn();
          data?.afterCallFunction(...data?.afterParams)
          mixinAlert("success", "Data uğurla əlavə olundu")
        }else{
          mixinAlert("error", "Xəta baş verdi")
        }
      })
    }else{
      mixinAlert("warning", "Zəhmət olmasa bütün xanaları doldurun");
    }
    
  }
  render() {
    const {mainModal} = this.props;
    const {btnDisable} = this.state;
    return (
      <div className='flex justify-between p-[20px] items-center'>
        <button disabled={btnDisable} onClick={this.closeBtn.bind(this)} className='bg-red-800 text-white text-[12px] font-semibold p-[2px_9px] rounded-[6px]'>Close</button>
        <button disabled={btnDisable} onClick={this.addBtn.bind(this, mainModal)} className='bg-blue-800 text-white text-[12px] font-semibold p-[2px_9px] rounded-[6px]'>Save</button>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue, insertNewData, resetStateValue}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)