import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getAbout } from '../actions/MainAction';
export class AdminAboutPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        image: data?.image?data?.image:"",
        image_full_url: data?.image_full_url?data?.image_full_url:"",
        languages:
          {
            id:lang?.id?lang?.id:'',
            text:lang?.text?lang?.text:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: [],
        requireds:[],
        afterCallFunction:this.props.getAbout,
        afterParams:[],
        url:'admin-about'
      }
    })
  }

  componentDidMount(){
    this.props.getAbout()
  }
  render() {
    const {aboutData} = this.props;
    // console.log(aboutData)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Haqqimizda" />
        <p>Text + Sekil</p>
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            aboutData?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-about/${data?.id}`}
                  afterCallFunction={()=> this.props.getAbout()}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    aboutData: state.Data.aboutData
});
const mapDispatchToProps = {changeStateValue, getAbout}
export default connect(mapStateToProps, mapDispatchToProps)(AdminAboutPage)



 
