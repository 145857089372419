import React, { Component } from 'react'

export class ClearSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m7.5 5.5-3.447 5.29a1.641 1.641 0 0 0-.043 1.723L7.5 18.5H18.86c.906 0 1.64-.735 1.64-1.641V7.14c0-.906-.734-1.641-1.64-1.641H7.5ZM10 8.5l7 7M10 15.5l6.93-7"
        />
      </svg>
    )
  }
}

export default ClearSvg