export const initialState = {
    loader: false,
    loaderLong: false,
    user:'',
    modalData: {
        position:false
    },
    contentData:[],
    languages:[],
    branchData:[],
    categoryData:[],
    subCategoryData:[],
    products:[],
    productsType:[],
    productImages:[],
    productSpesification:[],
    aboutData:[],
    cooperationCOmp:[],
    ourWorks:[]
}
