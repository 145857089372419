import React, { Component } from 'react'
import Logo from '../Logo'
import { NavLink } from 'react-router-dom'

export class SideBar extends Component {
  render() {
    return (
      <div className='min-w-[260px] w-[260px] h-screen bg-white'>
        <div className='w-[260px] h-screen bg-white overflow-auto fixed shadow'>
           <div className='mt-[20px]'>
                <Logo />
           </div>
           <nav>
            <ul className='pl-[20px] pr-[20px] mt-[40px] flex flex-col gap-[4px]'>
                <li >
                    <NavLink to={'/'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Əsas Slider</NavLink>
                </li>
                <li >
                    <NavLink to={'/content'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>content</NavLink>
                </li>
                <li >
                    <NavLink to={'/branch'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Fliallar</NavLink>
                </li>
                <li >
                    <NavLink to={'/product-category'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Mehsullar</NavLink>
                </li>
                <li >
                    <NavLink to={'/detail-category'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Ehtiyyat hisseleri</NavLink>
                </li>
                {/* <li >
                    <NavLink to={'/product-type'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Product type</NavLink>
                </li>
               
                <li >
                    <NavLink to={'/detail-type'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Detail type</NavLink>
                </li> */}
                <li >
                    <NavLink to={'/about'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Haqqimizda</NavLink>
                </li>
                <li >
                    <NavLink to={'/cooperation-companies'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Emekdashliq etdiyimiz Shirketler</NavLink>
                </li>
                <li >
                    <NavLink to={'/our-work'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Islerimiz</NavLink>
                </li>
               
            </ul>
           </nav>
        </div>
      </div>
    )
  }
}

export default SideBar