import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import MainModal from './components/modals/MainModal';
import Loader from './components/Loader';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import { getLoginUser } from './actions/MainAction';
import SideBar from './components/sidebar/SideBar';
import ContentPage from './pages/ContentPage';
import BranchPage from './pages/BranchPage';
import CategoryPage from './pages/CategoryPage';
import SubCategoryPage from './pages/SubCategoryPage';
import ProductsPage from './pages/ProductsPage';
import ProductTypes from './pages/ProductTypes';
import ProductImage from './pages/ProductImage';
import ProductSpesificationPage from './pages/ProductSpesificationPage';
import AdminAboutPage from './pages/AdminAboutPage';
import AdminCooperationCompanies from './pages/AdminCooperationCompanies';
import AdminOurWork from './pages/AdminOurWork';

function App() {

  const loader = useSelector(state=>state.Data.loader)
  const mainModal = useSelector(state=>state.Data.mainModal)
  const user = useSelector(state=> state.Data.user);

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getLoginUser())
    .then(resp=>{
      if(resp == "error"){
        navigate("/login")
      }
    })
  },[])
  return (
    <div className='flex'>
      {user !=""? <SideBar /> : null}
      
      <Routes>
        <Route path='/login' element={ <LoginPage />} />
        <Route path='/' element={ <MainPage />} />
        <Route path='/content' element={ <ContentPage />} />
        <Route path='/branch' element={ <BranchPage />} />
        <Route path='/product-category' element={ <CategoryPage type="product" />} />
        <Route path='/detail-category' element={ <CategoryPage type="detail" />} />

        {/* <Route path='/product-type' element={ <ProductTypes type="product" />} />
        <Route path='/detail-type' element={ <ProductTypes type="detail" />} /> */}

        <Route path='/sub-category/:id' element={ <SubCategoryPage />} />
        <Route path='/products/:id' element={ <ProductsPage />} />
        <Route path='/image/:id' element={ <ProductImage />} />
        <Route path='/spesification/:id' element={ <ProductSpesificationPage />} />
        <Route path='/about' element={ <AdminAboutPage />} />
        <Route path='/cooperation-companies' element={ <AdminCooperationCompanies />} />
        <Route path='/our-work' element={ <AdminOurWork />} />
      </Routes>
      {mainModal?.position? <MainModal /> :null}
      {loader? <Loader />:null}
    </div>
  )
}

export default App