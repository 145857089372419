import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
export class Input extends Component {
    changeInpValue=(e)=>{
      this.props.changeStateValue({
        name:e.target.name,
        value: e.target.value
      })
    }
  render() {
    const {type = "text", name="", value="", title="", required=false } = this.props;
    return (
        <div className='flex flex-col items-start'>
        {title !== "" ? <label htmlFor={name} className='text-[14px] txt-light-black'>{title}</label>:null }
        <input  onChange={this.changeInpValue.bind(this)} placeholder={title} id={name} name={name} value={value} type={type} className='bg-gray-100 w-full h-[35px] rounded-[6px] p-[5px] pl-[12px] outline-none text-[15px]' />
        {
            required && value===""?
            <span className='text-[11px] text-red-700'>{"This field is required"}</span>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Input)