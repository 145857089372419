import React, { Component } from 'react'
import { connect } from 'react-redux';
import Searchable from 'react-searchable-dropdown';
import { changeStateValue } from '../../../redux/MainReducer';
export class DropSearch extends Component {
    selectValue=(name, data )=>{
      console.log(data, name)
      this.props.changeStateValue({
        name:name,
        value:data
      })
    }
    getPlaceholder(placeholder, value, options){
        if(value === ""){
          return placeholder;
        }else{
          const check = options.find(item=>item?.value === value);
          if(check){
            return check?.label
          }else{
            return placeholder
          }
        }
      }
  render() {
    const {options=[], name, placeholder, title="", value="", required=false} = this.props;
    return (
        <div>
        {title !== "" ? <label className='text-[14px] txt-light-black'>{title}</label>:null }
        <Searchable
            placeholder={this.getPlaceholder(placeholder, value, options)}
            options={options}
            onSelect={this.selectValue.bind(this.label, name)}
        />
        {
            required && value===""?
            <span className='text-[11px] text-red-700'>{"This field is required"}</span>:null
        }
      </div>
    )
  }
}
const mapStateToPropse = (state)=>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToPropse, mapDispatchToProps)(DropSearch)