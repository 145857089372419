import React, { Component } from 'react'
import { connect } from 'react-redux';

export class LangBtns extends Component {
  state={
    active:0
  }
  changeLang=(index, data)=>{
    this.setState({active:index});
    this.props.onChangeLang(data?.id)
  }
  render() {
    const {languages} = this.props;
    const {active} = this.state;
    return (
      <div className='flex items-center gap-[20px] p-[0_20px] mt-[20px] border-b pb-[4px]'>
        {
          languages.map((data, i)=>{
            return(
              <button key={i} onClick={this.changeLang.bind(this, i, data)} className={`${active===i? 'bg-blue-800 text-white':"text-blue-800 border border-blue-800"}  h-[25px] p-[0_5px] rounded-[6px] text-[12px] font-semibold`}>{data?.title}</button>
            )
          })
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  languages: state.Data.languages
})

export default connect(mapStateToProps)(LangBtns)