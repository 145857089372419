import React, { Component } from 'react'
import CloseSvg from '../../svg/CloseSvg'

export class Header extends Component {
  render() {
    const {title, onCloseBtn=()=>{}} = this.props;
    return (
        <div className='bg-blue-900 rounded-t-[10px] h-[40px] flex items-center justify-between p-[0px_20px]'>
            <h3 className='text-[18px] text-white font-semibold'>{title}</h3>
            <button onClick={()=>onCloseBtn()}>
                <CloseSvg />
            </button>
        </div>
    )
  }
}

export default Header