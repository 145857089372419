import React, { Component } from 'react'
import logo from "../images/logo.png"
export class Logo extends Component {
  render() {
    return (
      <div className='flex flex-col items-center'>
        <img src={logo} alt="Logo" />
        {/* <p className='text-gray-600 font-semibold text-[22px]'>Admin</p> */}
      </div>
    )
  }
}

export default Logo