import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getProductTypes } from '../actions/MainAction';
export class ProductTypes extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        type:this.props.type,
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title"],
        requireds:[],
        afterCallFunction:this.props.getProductTypes,
        afterParams:[this.props.type],
        url:'admin-product-type'
      }
    })
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.type !== this.props.type){
        this.props.getProductTypes(this.props.type)
    }
  }
  componentDidMount(){
    this.props.getProductTypes(this.props.type)
  }
  render() {
    const {productsType} = this.props;
    // console.log(productsType)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Product Category" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            productsType?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-product-type/${data?.id}`}
                  afterCallFunction={()=> this.props.getProductTypes(this.props.type)}
                
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    productsType: state.Data.productsType
});
const mapDispatchToProps = {changeStateValue, getProductTypes}
export default connect(mapStateToProps, mapDispatchToProps)(ProductTypes)

