import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getLanguages } from '../actions/MainAction';
export class Header extends Component {
  componentDidMount(){
    this.props.getLanguages()
  }
  render() {
    const {title, btn=true, onClickBtn=()=>{}} = this.props;
    return (
      <div className='flex justify-between items-center' >
        <h2 className='text-[22px] font-semibold text-blue-700'>{title}</h2>
        {
          btn?
          <button onClick={()=>onClickBtn()} className='bg-blue-800 text-white text-[14px] font-semibold p-[4px_9px] rounded-[6px]'>Əlavə et</button>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {getLanguages}
export default connect(mapStateToProps, mapDispatchToProps)(Header)