import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getOurWorks } from '../actions/MainAction';
export class AdminOurWork extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        image:data?.image?data?.image:'',
        image_full_url:data?.image_full_url?data?.image_full_url:'',
        video:data?.video?data?.video:'',
        // video_full_url:data?.video_full_url?data?.video_full_url:'',
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            description:lang?.description?lang?.description:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title"],
        requireds:[],
        afterCallFunction:this.props.getOurWorks,
        afterParams:[this.props.type],
        url:'admin-our-work'
      }
    })
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.type !== this.props.type){
        this.props.getOurWorks(this.props.type)
    }
  }
  componentDidMount(){
    this.props.getOurWorks(this.props.type)
  }
  render() {
    const {ourWorks} = this.props;
    // console.log(ourWorks)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Islerimiz" />
        <p>youtube iframe tags</p>
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            ourWorks?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-our-work/${data?.id}`}
                  afterCallFunction={()=> this.props.getOurWorks(this.props.type)}
                
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    ourWorks: state.Data.ourWorks
});
const mapDispatchToProps = {changeStateValue, getOurWorks}
export default connect(mapStateToProps, mapDispatchToProps)(AdminOurWork)