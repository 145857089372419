import React, { Component } from 'react'
import { getProductsBySub, getProductTypes, getSingleData, insertNewData } from '../actions/MainAction';
import { connect } from 'react-redux';
import { withHooks } from '../actions/withHooks';
import Header from '../components/Header';
import { changeStateValue } from '../redux/MainReducer';
import { mixinAlert } from '../actions/SweetAlerts';
import Card1 from '../components/cards/Card1';
export class ProductsPage extends Component {
  state={
    subCategory:'',
    type:''
  }
  componentDidMount(){
    this.props.getSingleData(`admin-sub-categories/${this.props.params.id}?lang_id=1`)
    .then(resp=>{
      this.setState({subCategory: resp});
      this.props.getProductTypes(resp?.category.type)
      this.setState({type: resp?.category.type})
    });
    this.props.getProductsBySub(this.props.params.id)
  }

  getTypes(){
    let arr = [];
    for(const x of this.props.productsType){
      arr.push({
        label: x?.translate?.title,
        value:x?.id
      })
    }
    return arr;
  }
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        type:this.state.type,
        category_id: this.state.subCategory?.category_id,
        sub_category_id: this.state.subCategory?.id,
        // product_type_id: data?.product_type_id?data?.product_type_id:'',
        raiting: data?.raiting?data?.raiting:'',
        price: data?.price?data?.price:'',
        row: data?.row?data?.row: this.props.products.length,
        // productTypeOption: this.getTypes(),
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            description:lang?.description?lang?.description:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title"],
        requireds:[],
        afterCallFunction:this.props.getProductsBySub,
        afterParams:[this.props.params.id],
        url:'admin-product'
      }
    })
  }
  changeStatusBtn=(id, column, status)=>{
    let formData = new FormData();
    formData.append(column, status==0?1:0);
    formData.append("_method", "put");
    this.props.insertNewData(`admin-product/${id}`, formData)
    .then(resp=>{
      if(resp == "success"){
        mixinAlert("success", "data changed successfully");
        this.props.getProductsBySub(this.props.params.id)
      }
    })
  }
  render() {
    const {subCategory} = this.state;
    const {productsType, products} = this.props;
    console.log(products)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Products" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            products?.map((data, i) => {
              return (
                <div key={i} className='bg-white rounded-[10px] shadow'>
                    {/* <ul>
                      <li>Title: {data?.translate?.title}</li>
                      <li>Type: {data?.product_type?.translate?.title}</li>
                    </ul> */}
                   
                    <div className='flex justify-between items-center mt-[10px] pl-[10px] pr-[10px]'>
                      <button onClick={this.changeStatusBtn.bind(this, data?.id, "exists_status", data?.exists_status)} className={`${data?.exists_status ==0? "bg-blue-600":"bg-gray-600"} p-[3px_5px] text-[12px] text-white rounded-[5px] font-semibold`}>
                        Movcuddur
                      </button>
                      <button onClick={this.changeStatusBtn.bind(this, data?.id, "most_sale", data?.most_sale)} className={`${data?.most_sale==1? "bg-blue-600":"bg-gray-600"} p-[3px_5px] text-[12px] text-white rounded-[5px] font-semibold`}>
                        cox satilan
                      </button>
                      <button onClick={this.changeStatusBtn.bind(this, data?.id, "offer_status", data?.offer_status)} className={`${data?.offer_status==1? "bg-blue-600":"bg-gray-600"} p-[3px_5px] text-[12px] text-white rounded-[5px] font-semibold`}>
                        Teklif
                      </button>
                      <button onClick={this.changeStatusBtn.bind(this, data?.id, "main_page_status", data?.main_page_status)} className={`${data?.main_page_status==1? "bg-blue-600":"bg-gray-600"} p-[3px_5px] text-[12px] text-white rounded-[5px] font-semibold`}>
                        Main
                      </button>
                    </div>
                    <Card1
                      image={data?.image_full_url}
                      video={data?.video_full_url}
                      onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                      title1={data?.translate?.title}
                      title2={data?.product_type?.translate?.title}
                      deleteUrl={`admin-product/${data?.id}`}
                      afterCallFunction={()=> this.props.getProductTypes(this.props.type)}
                      imgPath={`/image/${data?.id}`}
                      url={`/spesification/${data?.id}`}
                      urlText="Info"
                      row={data?.row}
                    />
                </div>
              )
            })
          }
          
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  productsType: state.Data.productsType,
  products: state.Data.products,
});
const mapDispatchToProps = {getSingleData, getProductTypes, getProductsBySub, changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ProductsPage))