import axios from "axios";
import { MAIN_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";

export const loginAction = (params) => async dispatch => {
    return await axios.post(`${MAIN_API}/login`, params)
    .then(resp=>{
        localStorage.setItem("loginToken", resp?.data?.token)
        dispatch(changeStateValue({name:"user", value: resp?.data?.user}))

        return "success";
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}

export const getLoginUser=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/user`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"user", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}

export const getContent=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-content?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"contentData", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getAbout=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-about?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"aboutData", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getCooperations=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-cooperation-companies?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"cooperationCOmp", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getProductImage=(product_id)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-product-image?lang_id=1&product_id=${product_id}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"productImages", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getProductSp=(product_id)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-product-spesification?lang_id=1&product_id=${product_id}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"productSpesification", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getBranch=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-branch?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"branchData", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getCategory=(type)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-category?lang_id=1&type=${type}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"categoryData", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getSubCategory=(id)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-get-sub-categories/${id}?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"subCategoryData", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getProductsBySub=(id)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-get-products-byid/${id}?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"products", value: resp?.data}))
        return "success";
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}
export const getProductTypes=(type)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-product-type?lang_id=1&type=${type}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"productsType", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getOurWorks=(type)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-our-work?lang_id=1&type=${type}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"ourWorks", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}



export const getLanguages=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-language`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"languages", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getSingleData=(url)=>async dispatch => {
  
    return await axios.get(`${MAIN_API}/${url}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value: false}))
        
        return resp?.data;
    }).catch(err=>{
        return "";
    })
}



export const insertNewData=(url, params)=> async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}))
    return await axios.post(`${MAIN_API}/${url}`, params, {
        headers: { "Authorization": `Bearer ${localStorage.getItem("loginToken")}`}
    }).then(resp=>{
        console.log(resp.data)
        dispatch(changeStateValue({name: "loader", value:false}))
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}))
        console.log(err.response)
        return "error"
    })
}
export const deleteData=(url)=> async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}))
    return await axios.delete(`${MAIN_API}/${url}`, {
        headers: { "Authorization": `Bearer ${localStorage.getItem("loginToken")}` }
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}))
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}))
        console.log(err.response)
        return "error"
    })
}