import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import ClearSvg from '../../svg/ClearSvg';
export class VideoInp extends Component {
    state={
        base64:''
    }

    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = () => {
                res(reader.result)
            }
            reader.readAsDataURL(file)
        })
    }
  getInpValue(e){
    this.getBase64(e.target.files[0])
    .then(resp=>{
        this.props.changeStateValue({
            name: "mainModal.video_full_url",
            value: resp
        });
        this.props.changeStateValue({
            name: 'mainModal.video',
            value: e.target.files[0]
        })
    });
  }
  removeFile=()=>{
    this.props.changeStateValue({
        name: "mainModal.video_full_url",
        value: ""
    });
    this.props.changeStateValue({
        name: 'mainModal.video',
        value: ""
    })
  }
  render() {
    const {mainModal} = this.props
    return (
      <div className='flex flex-col'>
        <label className={"text-black text-sm"} htmlFor={"inpFileVideo"}>{"Upload video"}</label>
        <div className={"bg-gray-100 p-2 rounded-xl text-sm outline-none flex jsutify-between items-center"}>
            <input onChange={this.getInpValue.bind(this)} accept='video/*' id={"inpFileVideo"} type={"file"} name={"video"}  />
            <button onClick={this.removeFile.bind(this)}>
                <ClearSvg />
            </button>
        </div>
        {
            mainModal.video_full_url !=""?
             <video controls className='mt-[20px] w-full' src={mainModal?.video_full_url} alt="video" />:null
        }
       
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    mainModal: state.Data.mainModal
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(VideoInp)