import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getProductSp } from '../actions/MainAction';
import { withHooks } from '../actions/withHooks';
export class ProductSpesificationPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        product_id: data?.product_id?data?.product_id:this.props.params.id,
        type: data?.type?data?.type:'',
        prSpesificationTypeOpt: [{label:"Spesification", value:"spesification"}, {label:"Properties", value: "properties"}],
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title"],
        requireds:[],
        afterCallFunction:this.props.getProductSp,
        afterParams:[this.props.params.id],
        url:'admin-product-spesification'
      }
    })
  }

  componentDidMount(){
    this.props.getProductSp(this.props.params.id)
  }
  render() {
    const {productSpesification} = this.props;
    // console.log(productSpesification)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Product Info" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            productSpesification?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  title2={data?.type}
                  deleteUrl={`admin-product-spesification/${data?.id}`}
                  afterCallFunction={()=> this.props.getProductSp(this.props.params.id)}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    productSpesification: state.Data.productSpesification
});
const mapDispatchToProps = {changeStateValue, getProductSp}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ProductSpesificationPage))



 
